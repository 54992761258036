var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-table board-table--input",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4), _vm._m(5), _vm._l(_vm.files, function (item, index) {
    return _c('div', {
      attrs: {
        "role": "row"
      }
    }, [index == 0 ? _c('div', {
      attrs: {
        "role": "columnheader"
      }
    }, [_vm._v("썸네일 이미지")]) : _c('div', {
      attrs: {
        "role": "columnheader"
      }
    }, [_vm._v("첨부파일 " + _vm._s(index))]), _c('div', {
      attrs: {
        "role": "cell"
      }
    }, [_c('div', {
      staticClass: "file-wrap"
    }, [_c('label', {
      staticClass: "file",
      attrs: {
        "for": 'upfile' + index
      }
    }, [_c('input', {
      staticClass: "input",
      attrs: {
        "id": 'upfile' + index,
        "type": "file"
      },
      on: {
        "change": function ($event) {
          return _vm.fileChange(index);
        }
      }
    }), _c('span', {
      staticClass: "text"
    }, [_c('span', [_vm._v(_vm._s(item.name))])]), _c('span', {
      staticClass: "button button--primary"
    }, [_vm._v("파일선택")])])])])]);
  })], 2)]), _vm._m(6)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("작성자")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-input"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "text"
    }
  })])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("이메일")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-input"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "text"
    }
  })])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("작성일")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-input"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "text"
    }
  })])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("제목")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-input"
  }, [_c('select', {
    staticClass: "select",
    attrs: {
      "name": "",
      "id": ""
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("카테고리")])]), _c('input', {
    staticClass: "input",
    attrs: {
      "type": "text"
    }
  })])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("라디오/체크박스")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "radio-wrap"
  }, [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "radio",
      "name": "radio1",
      "value": ""
    }
  }), _c('i', {
    staticClass: "icon"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("라디오1")])]), _c('label', {
    staticClass: "radio"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "radio",
      "name": "radio1",
      "value": ""
    }
  }), _c('i', {
    staticClass: "icon"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("라디오2")])])]), _c('div', {
    staticClass: "check-wrap"
  }, [_c('label', {
    staticClass: "check"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "checkbox",
      "name": "check1",
      "value": ""
    }
  }), _c('i', {
    staticClass: "icon"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("체크박스1")])]), _c('label', {
    staticClass: "check"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "checkbox",
      "name": "check1",
      "value": ""
    }
  }), _c('i', {
    staticClass: "icon"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("체크박스2")])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "300px",
      "border": "1px solid #ddd"
    }
  }, [_vm._v(" 에디터 영역 ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-buttons board-buttons--right-center"
  }, [_c('div', {
    staticClass: "right"
  }, [_c('a', {
    staticClass: "button button--border",
    attrs: {
      "href": ""
    }
  }, [_c('span', [_vm._v("취소")])]), _c('a', {
    staticClass: "button button--primary",
    attrs: {
      "href": ""
    }
  }, [_c('span', [_vm._v("확인")])])])]);

}]

export { render, staticRenderFns }